import { RowFlex } from '../Layout'
import { Checkbox, IconButton, Typography } from '@mui/material'
import React from 'react'

interface props {
  handleCheck: any
  check: boolean
  text?: any

  indeterminate?: boolean | undefined
  disable?: boolean
  className?: string

  customIcon?: any
  customCheckedIcon?: any
  customIndeterminateIcon?: any
  checkboxSize?: 'small' | 'medium'
}

export const SelectAllCheckbox = React.forwardRef(
  (
    {
      handleCheck,
      check,
      text = 'Select All',

      indeterminate,
      disable = false,
      className,
      customIcon,
      customCheckedIcon,
      customIndeterminateIcon,
      checkboxSize,
    }: props,
    ref
  ) => {
    if (!indeterminate) {
      indeterminate = undefined
    }

    let classNameText = 'text-smTitle color-black'

    if (disable) {
      classNameText = 'text-smTitle'
    }
    if (!disable) {
      classNameText = 'text-smTitle color-black'
    }

    return (
      <IconButton
        disabled={disable}
        // disableRipple
        onClick={handleCheck}
        className={`${className ?? ' '}  button-fullwidth ripple-resizing`}
        sx={{
          padding: 0,
        }}
        ref={ref}
      >
        <RowFlex fullWidth crossAxisAlignment="center">
          <Checkbox
            size={checkboxSize}
            checked={check}
            indeterminate={indeterminate}
            disabled={disable}
            icon={customIcon ?? undefined}
            checkedIcon={customCheckedIcon ?? undefined}
            indeterminateIcon={customIndeterminateIcon ?? undefined}
          />
          <Typography className={classNameText} component="div">
            {text}
          </Typography>
        </RowFlex>
      </IconButton>
    )
  }
)
